
<template>
  <div>
    <!-- Scrollspy -->
    <div id="scrollspy-collapsible">
      <div class="card">
        <div style="position: relative;">
          <FileUpload mode="basic" name="demo[]" :url="useApi.myapiurl + 'TinyMCE/Upload'" accept="image/*" auto
            :maxFileSize="2000000"
            :style="{ 'width': '100%', 'height': '15em', 'background-size': 'cover', 'background-image': myobj.fImgUrl }"
            customUpload @uploader="customBase64Uploader" />
        </div>
        <div>
          <div class="card-body">
            <div>

              <ObjectShare widthclass="div_33_100" @ondatachange="getrolevalue" :sharedata="getshare"></ObjectShare>

              <div class="clear"></div>
              <div class="place1em"></div>
              <FileUpload :disabled="!sendshare.sShareGroup" mode="basic" name="demo[]" :maxFileSize="1024000000"
                :multiple="false" :auto="true" chooseLabel="上传附件" customUpload @uploader="customFileUploader" />

              <!-- {fDloadName,fLocalUrl,fOtherUrl,fDisplayName,fLabel,fDescription,fViewTimes,fDownloadTimes,fUpTimes,fBelongInfo,fStyle1,fStyle2,,fType} in file -->
              <div class="div_100 margin_top">
                <div class="p-inputgroup flex-1 ">
                  <span class="p-float-label">
                    <InputText placeholder="文件名称" v-model="myobj.fDisplayName" @change="selectid = bid" /><Button
                      icon="pi pi-search" :title="myobj.fId" @click="search_blog()" />
                    <label for="uGangwei">文件名称</label>
                  </span>
                </div>
              </div>
              <div class="div_66_100 margin_top">
                <div class="p-inputgroup flex-1 ">
                  <span class="p-float-label">
                    <InputText placeholder="网盘路径" v-model="myobj.fOtherUrl" />
                    <label for="uGangwei">网盘路径</label>
                  </span>
                </div>
              </div>
              <div class="div_34_100 margin_top">
                <div class="p-inputgroup flex-1 ">
                  <span class="p-float-label">
                    <InputText placeholder="网盘密码" v-model="myobj.fPassWord" />
                    <label for="uGangwei">网盘密码</label>
                  </span>
                </div>
              </div>
              <div class="div_100 margin_top">
                <div class="p-inputgroup flex-1 ">
                  <span class="p-float-label">
                    <InputText placeholder="关键字" v-model="myobj.fLabel" />
                    <label for="uGangwei">关键字</label>
                  </span>
                </div>
              </div>
              <div class="div_100 margin_top">
                <div class="p-inputgroup flex-1 ">
                  <span class="p-float-label">
                    <InputText placeholder="描述" v-model="myobj.fDescription" />
                    <label for="uGangwei">描述</label>
                  </span>
                </div>
              </div>
              <div class="div_33_100 margin_top">
                <div class="p-inputgroup flex-1 ">
                  <span class="p-float-label">
                    <Dropdown v-model="myobj.fStyle1" placeholder="大类型" class="w-full md:w-14rem"
                      :options="objoptions.fStyle1s" editable option-label="fStyle1" :loading="false" />
                    <label for="uGangwei">大类型</label>
                  </span>
                </div>
              </div>
              <div class="div_33_100 margin_top">
                <div class="p-inputgroup flex-1 ">
                  <span class="p-float-label">
                    <Dropdown v-model="myobj.fStyle2" placeholder="小类型" class="w-full md:w-14rem"
                      :options="objoptions.fStyle2s" editable option-label="fStyle2" :loading="false" />
                    <label for="uGangwei">小类型</label>
                  </span>
                </div>
              </div>
              <div class="div_33_100 margin_top">
                <div class="p-inputgroup flex-1 ">
                  <span class="p-float-label">
                    <Dropdown v-model="myobj.fManufacture" placeholder="品牌厂家" class="w-full md:w-14rem"
                      :options="objoptions.fManufactures" editable option-label="fManufacture" :loading="false" />
                    <label for="uGangwei">品牌厂家</label>
                  </span>
                </div>
              </div>
              <div class="div_100 margin_top">
                <div class="p-inputgroup flex-1 ">
                  <span class="p-float-label">
                    <InputText placeholder="文件路径" v-model="myobj.fFullPath" @change="selectid = bid" />
                    <label for="uGangwei">文件路径</label>
                  </span>
                </div>
              </div>
              <div class="clear"></div>

            </div>
          </div>
          <div>
            <Button icon="pi pi-plus" @click="create_blog" v-if="myobj.fId <= 0"
              style="margin: 0.1em;position: fixed;right: 1em;bottom: 6em;z-index: 99;" />
            <Button icon="pi pi-refresh" @click="update_blog()" :title="myobj.fId" v-if="myobj.fId > 0"
              style="margin: 0.1em;position: fixed;right: 1em;bottom: 6em;z-index: 99;" />
            <Button icon="pi pi-minus" @click="delete_blog()" :title="myobj.fId" v-if="myobj.fId > 0"
              style="margin: 0.1em;" />
            <Button @click="useApi.jumpto('/download/' + props.blog_id)" v-if="props.blog_id" icon="pi pi-video"
              style="margin: 0.1em;position: fixed;right: 1em;bottom: 2em;z-index: 99;" />
          </div>
          <div style="text-align: left;padding: 1em;">

          </div>
        </div>

      </div>

      <div style="height: 1em;"></div>
      <div data-mdb-spy="scroll" data-mdb-target="#scrollspy-collapsible" data-mdb-offset="0" class="scrollspy-example">

        <editor v-model="myobj.fText" id="EditPanel3" :init="useEditor.init_old" />

      </div>
      <div style="height: 1em;"></div>
    </div>

  </div>
</template>
  
<script setup>
import Editor from '@tinymce/tinymce-vue'
import { ref, defineProps, onMounted, defineEmits } from "vue";
import InputText from 'primevue/inputtext';
import FileUpload from 'primevue/fileupload';
import Button from 'primevue/button';
import Dropdown from 'primevue/dropdown';
import useCommon from '@/services/useCommon';
import useApi from '@/services/useApi';
import ObjectShare from '@/components/ObjectShare.vue';
import useSlade from '@/services/useSlade'
import useAlert from '@/services/useAlert';
import useEditor from '@/services/useEditor';
let myobj = ref({});
myobj.value.fImgUrl = 'url(images/f2.jpg)';
let objoptions = ref({})
let sendshare = ref({});
let getshare = ref({});
sendshare.value.Id = -1
const getrolevalue = (obj) => {
  sendshare.value = obj
}


const get_configs = async () => {
  objoptions.value.fStyle1s = []
}

const beforepost = (obj) => {
  if (obj.fStyle1)
    obj.fStyle1 = useCommon.o_s(obj.fStyle1, "fStyle1")
  if (obj.fStyle2)
    obj.fStyle2 = useCommon.o_s(obj.fStyle2, "fStyle2")
  if (obj.bOrderSN)
    obj.fManufacture = useCommon.o_s(obj.fManufacture, "fManufacture")
}

const create_blog = async () => {
  if (myobj.value.fDisplayName && myobj.value.fLabel && myobj.value.fDescription && myobj.value.fStyle1 && myobj.value.fStyle2 && myobj.value.fManufacture) {
    let postgroup = useCommon.o_o(myobj.value)
    beforepost(postgroup);
    await useSlade.add_file(postgroup, sendshare.value);
    search_blog();
  }
  else
    useAlert.salert("信息不完整")
}

const update_blog = async () => {
  if (myobj.value.fDisplayName && myobj.value.fLabel && myobj.value.fDescription && myobj.value.fStyle1 && myobj.value.fStyle2 && myobj.value.fManufacture) {
    let postgroup = useCommon.o_o(myobj.value)
    beforepost(postgroup);
    await useSlade.upd_file(postgroup, sendshare.value);
    search_blog();
  } else
    useAlert.salert("信息不完整")
}

const delete_blog = async () => {
  let postgroup = useCommon.o_o(myobj.value)
  beforepost(postgroup);
  await useSlade.del_file(postgroup, sendshare);
  search_blog();
}

let isnewobj = ref(true)

const props = defineProps({
  blog_id: String,
  style: String
})

const search_blog = async () => {
  let postgroup = useCommon.o_o(myobj.value)
  beforepost(postgroup);
  let getresult = await useSlade.get_file(postgroup);
  if (getresult && getresult.fDisplayName) {
    getshare.value = getresult
    myobj.value = getresult
    isnewobj.value = false;
  }
  else {
    myobj.value.fId = -1;
    isnewobj.value = true;
  }
  ondatachange()
}

onMounted(() => {
  get_configs();
  console.log(props.blog_id)
  if (props.blog_id && props.blog_id.length > 0) {
    myobj.value.fId = Number(props.blog_id);
    search_blog();
  }
})

const customBase64Uploader = async (event) => {

  const file = event.files[0];
  let formData = new FormData()
  formData.append('files', file)
  useApi.uploadfile('TinyMCE/Upload', formData).then(res => {
    myobj.value.fImgUrl = 'url(' + useApi.myapiurl + res.data.location + ')';

  })
}

const customFileUploader = async (event) => {

  let result = await useApi.uploadfile('api/File/' + props.style, event);
  myobj.value.fDisplayName = result.data
  search_blog()
};
const emits = defineEmits(['ondatachange']);
function ondatachange() {
  emits("ondatachange")
}
</script>

  