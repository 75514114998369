<template>
    
    
    <div v-if="!isShow">
        
        <div v-if="!isEdit">
            <Button icon="pi pi-plus" @click="switch_edit" v-if="authed"
        style="margin: 0.1em;position: fixed;right: 1em;bottom: 2em;z-index: 99;" />
            <Button icon="pi pi-car" @click="useApi.jumpto('/device/show')" title="查看电子元器件"
        style="margin: 0.1em;position: fixed;right: 1em;bottom: 6em;z-index: 99;" />
            <div style="height: 1.5em;"></div>
            <div>
                <SearchBlock :sname="'规格书'" @onsearch="filters" :flabel="filter_arr"></SearchBlock>
            </div>
            <div class="padding" style="min-height: 1500px;">
                <div v-for="{ Description, Name, Style1, Style2, Manufacture, Price, Id,FileName } in blogs" :key="Id"
                    style="padding: 1em;" class="div_100">

                    <div style="padding: 1em;border-top: 1px solid #e6e6e6;">
                        <div class="div_20_100 align_lleft_scenter"><i class="pi pi-globe"></i> {{
                            useCommon.jsons_o(Manufacture).vName }}</div>
                        <div class="div_20_100 align_lleft_scenter"><i class="pi pi-bookmark"></i> {{
                            useCommon.jsons_o(Style1).vStyle1 }}</div>
                        <div class="div_20_100 align_lleft_scenter"><i class="pi pi-bookmark"></i> {{
                            useCommon.jsons_o(Style2).vStyle2 }}</div>
                        <div class="div_20_100 align_lleft_scenter">
                            <a :href="'/datasheet/show/' + Id" @click="isShow=true;showid=Id"><i class="pi pi-tag"></i> {{ Name }}</a>
                        </div>
                        <div class="div_20_100 align_lleft_scenter"><i class="pi pi-money-bill"></i> {{ Price }}</div>
                        <div v-if="Description&&Description.length>0" class="div_100 align_lleft_scenter" style="height: 1.5em; overflow: hidden;">
                            <i class="pi pi-home"></i> {{ Description }}                           
                        </div>
                        <div v-else class="div_100 align_lleft_scenter" style="height: 1.5em; overflow: hidden;">                           
                            <i class="pi pi-home"></i> {{ FileName }}
                        </div>

                        <div class="clear"></div>
                    </div>
                </div>
                <div class="clear"></div>

            </div>
        </div>
        <div v-else>  
            <Button icon="pi pi-home" @click="switch_edit" v-if="authed"
        style="margin: 0.1em;position: fixed;right: 1em;bottom: 2em;z-index: 99;" />          
            <DataSheetEdit @ondatachange="listshow" :blog_id="props.project_id"></DataSheetEdit>
            <FireDeviceStyle @ondatachange="listshow" :blog_id="projectname"></FireDeviceStyle>
            <FireDVEdit @ondatachange="listshow" :blog_id="projectname"></FireDVEdit>
        </div>
    </div>
    <div v-else>
        <Button icon="pi pi-home" @click="switch_show" v-if="authed"
        style="margin: 0.1em;position: fixed;right: 1em;bottom: 6em;z-index: 99;" />
        <Button icon="pi pi-file-edit" @click="switch_edit" v-if="authed"
        style="margin: 0.1em;position: fixed;right: 1em;bottom: 2em;z-index: 99;" />
        <PdfReader :pdfid="showid"></PdfReader>
    </div>
</template>
    
<script setup>
import FireDVEdit from '@/components/FireDVEdit.vue';
import FireDeviceStyle from '@/components/FireDeviceStyle.vue';
import DataSheetEdit from '@/components/DataSheetEdit.vue';
import useApi from "@/services/useApi";
import { ref, onMounted, defineProps } from "vue";
import Button from 'primevue/button';
import { useStore } from "vuex";
import SearchBlock from "@/components/SearchBlock.vue";
import useSlade from '@/services/useSlade';
import useCommon from '@/services/useCommon'
import useFree from '@/services/useFree';
import PdfReader from '@/components/PdfReader.vue';
const props = defineProps({
    filter: String,
    project_id: String
})
let isEdit = ref(false)
let isShow = ref(false)
let showid = ref(0)
let projectname = ref('')
const store = useStore();
let authed = ref(store.state.authed);
let blogs = ref([])
let local_blogs = ref([])

const filters = (name, type) => {
    blogs.value = useApi.search_item(name, type, local_blogs.value)
}
const filter_arr = [{ name: '名称', type: 'Name' }, { name: '大类型', type: 'Style1' }, { name: '小类型', type: 'Style2' }, { name: '描述', type: 'Description' }, { name: '厂家', type: 'Manufacture' }]
const listshow = async () => {
    if (store.state.authed)
        blogs.value = await useSlade.get_allds()
    else
        blogs.value = await useFree.get_allds()
    console.log(blogs.value)
    local_blogs.value = blogs.value

}


const switch_edit = () => {
    isShow.value=false;
    if (isEdit.value) {
        isEdit.value = false;
        projectname = '';
        window.location.href = '/datasheet/show'
    }
    else {
        isEdit.value = true;
    }
}
const switch_show = (obj) => {
    isEdit.value=false
    if (isShow.value) {
        isShow.value = false;
        window.location.href = '/datasheet/show'
    }
    else {
        isShow.value = true;
        showid.value=obj.Id
    }
}


onMounted(() => {
    listshow();
    if (props.filter && props.filter.length > 0) {
        filters()
    }
    if (props.project_id) {        
        isShow.value = true;
        showid.value = props.project_id
    }
    
    document.title = '[元器件手册]-[碳硅锗兮]';
document.querySelector('meta[name="keywords"]').setAttribute('content', '碳硅锗兮登录和注册,分享软硬件设计笔记和资源经验')
document.querySelector('meta[name="description"]').setAttribute('content', '碳硅锗兮登录和注册,分享软硬件设计笔记和资源经验')
})
</script>
    