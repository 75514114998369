/*该控件为通用的树形显示控件,可以进行数据展示,数据更新，数据删除。*/
<template>  

    <div v-for="{ index } in mydata" :key="index" >
        <div style="text-align:left;position: relative;">
            <div style="font-size: 1em;margin-top: 1em;min-height: 3em;" v-if="(mydata[index])">
                <div style="font-weight: bold;font-size:1.1em" :title=" mydata[index].pBelongInfo.uGroupName+'@'+ mydata[index].pBelongInfo.uCompanyName ">                    
                    <div style="text-align: left;" :title="mydata[index].group_name" v-if="props.isparent != -1">
                        <i class="pi pi-book" ></i> {{ mydata[index].pPlan }}
                    </div> 
                    <div style="text-align: center;" v-else>
                        <h1>{{ mydata[index].pPlan }}</h1> 
                    </div>  
                    
                </div>  
                <div>
                    <CommonBlogBlock :blog_id="mydata[index].pFiles" :menu_id="mydata[index].pId" :isparent="props.isparent" :is_messageshow="mydata[index].children && mydata[index].children.length > 0"></CommonBlogBlock>
                </div>
                
            </div>
            <div v-if="mydata[index]" style="padding-left: 1em;">
                <div v-if="mydata[index].children && mydata[index].children.length > 0" >
                    <CommonBlogs :isparent="mydata[index].pId" :datas="mydata[index].children" :userid="userid" :setroledata="props.setroledata" :pstyle="props.pstyle" @datachange="datachange"></CommonBlogs>
                </div>               
            </div>
            
        </div>
    </div>
    <div class="clear"></div>    
    <div class="place1em"></div>
</template>
<script setup>
import { defineProps,defineEmits,defineExpose } from 'vue';
import useCommonMap from '@/services/useCommonMap'
import CommonBlogBlock from '@/components/CommonBlogBlock.vue'

const props = defineProps({
    isparent: Number,
    pid:String,
    datas: Array,
    userid: Number,
    pstyle: String,
    pheader: String,
    setroledata: Object,
    belonginfo: Object
});

const { mydata,  getnblogs } = useCommonMap(props)


const emits = defineEmits(['datachange']);
async function datachange() {
    console.log(props.isparent,"datachange")
    emits("datachange")
    await getnblogs()
}

defineExpose({
    getnblogs
});

</script>
<style>
    .box_blog {
      padding: 0.1em 1em 0.1em 1em; 
      margin-bottom: 0.5em;
      color: white;
      border-radius: 1em;
      border: 1px solid white;
    
    }
</style>