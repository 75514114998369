import { createRouter, createWebHistory } from 'vue-router'
import store from '@/store'

import Blogs from '../views/FireBlogs.vue'
import BlogShow from '../views/BlogShow.vue'
import BlogWrite from '../views/BlogWrite.vue'
import FireMyself from '../views/FireMyself.vue'
import FireProject from '../views/FireProject.vue'
import FireProjectManager from '../views/FireProjectManager.vue'
import CarMove from '../views/CarMove.vue'
import FireFactory from '../views/FireFactory.vue'
import FireFiles from '../views/FireFiles.vue'
import FireFileShow from '../views/FireFileShow.vue'
import FireDeviceShow from '../views/FireDeviceShow.vue'
import FireDatasheetShow from '../views/FireDatasheetShow.vue'
import FireClistShow from '../views/FireClistShow.vue'
import FireManager from '../views/FireManager.vue'

//import HomeView from '../views/HomeView.vue'
import FireLogin from '../views/FireLogin.vue'
import FireMyInfo from '../views/FireMyInfo.vue'
import FireLogout from '../views/FireLogout.vue'
import NBlogShow from '../views/NBlogShow.vue'

const infoname='infomation'
const loginname='mylogin'
const loginurl='/login'

const routes = [
  {
    path: '/',
    name: 'home',
    component: Blogs 
  },
  {
    path: '/login',
    name: 'mylogin',    
    component: FireLogin 
  },
  {
    path: '/info',
    name: 'infomation',    
    component: FireMyInfo
  }, 
  {
    path: '/logout',
    name: 'logout',    
    component: FireLogout
   
  }, 
  {
    path: '/manager',
    name: 'manager',
    component: FireManager,
    
  }
  ,
  {
    path: '/bloglist/:filter?',
    name: 'blogs',
    component: Blogs,
    props:true
  }
  ,
  {
    path: '/fileshare/:project_id?',
    name: 'fileshare',    
    component: FireFiles,    
    props:true
  }
  ,
  {
    path: '/blogs/:blog_id',
    name: 'blogshow',    
    component: BlogShow,
    props:true
  }
  ,
  {
    path: '/nblogs/:blog_id',
    name: 'newblogshow',    
    component: NBlogShow,
    props:true
  }
  ,
  {
    path: '/download/:blog_id',
    name: 'downloadshow',    
    component: FireFileShow,
    props:true
  }
  ,
  {
    path: '/blogs/write/:blog_id?',    
    name: 'blogwrite',    
    component: BlogWrite,
    meta: { isAuth: true, title:'写笔记' },
    props:true
  },

  {    
    path: '/myself',
    name: 'myself',    
    component: FireMyself
  },
  {    
    path: '/projects/:project_id?',
    name: 'projects',    
    component: FireProject,
    props:true
  },
  {    
    path: '/app/carmove/:carid',
    name: 'app-carmove',    
    component: CarMove,
    props:true
  },
  {    
    path: '/project/:project_id',
    name: 'project',    
    component: FireProjectManager,
    props:true
  },
  {    
    path: '/factory/:dut_id?',
    name: 'factory',    
    component: FireFactory,
    props:true
  },
  {    
    path: '/device/show/:project_id?',
    name: 'deviceshow',    
    component: FireDeviceShow,
    props:true
  }
  ,
  {    
    path: '/datasheet/show/:project_id?',
    name: 'datasheet',    
    component: FireDatasheetShow,
    props:true
  }
  ,
  {    
    path: '/checklist/show/:filter?',
    name: 'checkshow',    
    component: FireClistShow,
    props:true
  }
  
]

const router = createRouter({
  //history: createWebHashHistory(),
  history: createWebHistory(),
  routes
})


router.beforeEach((to, from, next) => {
  //如果登录了，则不要进入login界面
  if (JSON.parse(store.state.authed) && to.name == loginname) { 
    //alert("ss")   
    next({ name: infoname })
  }

  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (to.meta.isAuth) {
      next({
        path: loginurl,
        query: { redirect: to.fullPath } // 把要跳转的地址作为参数传到下一步
      })
    } else {
      next()
    }
  } 
  else 
  {
    if (to.query && to.query.redirect) {
      if (router.app.$cookies.get("status") === "logined") {
        next({path: to.query.redirect})
      } else {
        next()
      }
    } else {
      next() // 确保一定要调用 next()
    }

    
  }
})

export default router
