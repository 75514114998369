import api from '@/services/useApi';
import useAlert from './useAlert';

const alert = useAlert.salert
const postapi = api.postapi;


async function setvalue(apiname,data, share) {
    {
        await postapi("slade/user",
            {
                apiname: apiname,
                data: data,
                share: share
            }).then(res => {
                alert(res.data);
            })
    }
}
async function getvalues(apiname,data) {
    const memdata = await postapi("slade/user",
        {
            apiname: apiname,
            data: data
        })
    if (memdata.data && memdata.data.data)
        return memdata.data.data
}
async function getvalue(apiname,data) {
   
    const memdata = await postapi("slade/user",
        {
            apiname: apiname,
            data: data
        })

    if (memdata.data && memdata.data.data[0])
        return memdata.data.data[0]
   
}

const update_info   =async (obj)    =>{return await setvalue("update_info",obj)}
const get_info      =async()        =>{return await getvalue("get_info")}
const get_logininfo =async()        =>{return await getvalue("get_logininfo")}

const create_group  =async(obj)     =>{return await setvalue("create_group",obj)}
const join_group    =async(obj)     =>{return await setvalue("join_group",obj)}
const get_mygroup   =async()        =>{return await getvalues("get_mygroup")}
const get_group     =async(obj)     =>{return await getvalue("get_group",obj)}
const get_joinmems  =async(obj)     =>{return await getvalues("get_joinmems",obj)}
const delete_memgroup  =async(obj)     =>{return await setvalue("delete_memgroup",obj)}
const delete_group  =async(obj)     =>{return await setvalue("delete_group",obj)}
const agree_memgroup  =async(obj)     =>{return await setvalue("agree_memgroup",obj)}
const get_joindmems  =async(obj)     =>{return await getvalues("get_joindmems",obj)}
const get_joindgroup  =async(obj)     =>{return await getvalues("get_joindgroup",obj)}

const add_project  =async(obj,share)     =>{return await setvalue("add_project",obj,share)}
const upd_project    =async(obj,share)     =>{return await setvalue("upd_project",obj,share)}
const get_project     =async(obj)     =>{return await getvalue("get_project",obj)}
const get_projects     =async(obj)     =>{return await getvalues("get_projects",obj)}
const del_project     =async(obj)     =>{return await getvalues("del_project",obj)}
const get_cspros     =async(obj)     =>{return await getvalues("get_cspros",obj)}
const getcs_projects     =async(obj)     =>{return await getvalues("getcs_projects",obj)}
const get_cslines     =async(obj)     =>{return await getvalues("get_cslines",obj)}
const get_csstyles     =async(obj)     =>{return await getvalues("get_csstyles",obj)}
const get_csstates     =async(obj)     =>{return await getvalues("get_csstates",obj)}


const add_plan  =async(obj,share)     =>{return await setvalue("add_plan",obj,share)}
const upd_plan    =async(obj,share)     =>{return await setvalue("upd_plan",obj,share)}
const get_plan     =async(obj)     =>{return await getvalue("get_plan",obj)}
const get_plans     =async(obj)     =>{return await getvalues("get_plans",obj)}
const del_plan     =async(obj)     =>{return await setvalue("del_plan",obj)}
const get_gplans     =async(obj)     =>{return await getvalues("get_gplans",obj)}
const get_oneday     =async(obj)     =>{return await getvalues("get_oneday",obj)}
const finish_plan     =async(obj)     =>{return await setvalue("finish_plan",obj)}
const recheck_plan     =async(obj)     =>{return await setvalue("recheck_plan",obj)}
const get_nblogs     =async(obj)     =>{return await getvalues("get_nblogs",obj)}
const bind_nblog     =async(obj)     =>{return await setvalue("bind_nblog",obj)}

const add_today  =async(obj,share)     =>{return await setvalue("add_today",obj,share)}
const upd_today    =async(obj,share)     =>{return await setvalue("upd_today",obj,share)}
const get_today    =async(obj,share)     =>{return await getvalue("get_today",obj,share)}
const del_today    =async(obj,share)     =>{return await setvalue("del_today",obj,share)}

const add_blog  =async(obj,share)     =>{return await setvalue("add_blog",obj,share)}
const upd_blog    =async(obj,share)     =>{return await setvalue("upd_blog",obj,share)}

const get_blog     =async(obj)     =>{return await getvalue("get_blog",obj)}

const get_blogs     =async(obj)     =>{return await getvalues("get_blogs",obj)}
const del_blog     =async(obj)     =>{return await setvalue("del_blog",obj)}
const get_blogid     =async(obj)     =>{return await getvalue("get_blogid",obj)}
const get_diarys     =async(obj)     =>{return await getvalues("get_diarys",obj)}
const get_conts     =async(obj)     =>{return await getvalues("get_conts",obj)}
const get_bsconts     =async(obj)     =>{return await getvalues("get_bsconts",obj)}
const get_names     =async(obj)     =>{return await getvalues("get_names",obj)}

const add_file  =async(obj,share)     =>{return await setvalue("add_file",obj,share)}
const upd_file    =async(obj,share)     =>{return await setvalue("upd_file",obj,share)}
const get_file     =async(obj)     =>{return await getvalue("get_file",obj)}
const get_files     =async(obj)     =>{return await getvalues("get_files",obj)}
const del_file     =async(obj)     =>{return await setvalue("del_file",obj)}
const get_pjfiles     =async(obj)     =>{return await getvalues("get_pjfiles",obj)}

const add_vender  =async(obj,share)     =>{return await setvalue("add_vender",obj,share)}
const upd_vender    =async(obj,share)     =>{return await setvalue("upd_vender",obj,share)}
const get_vender     =async(obj)     =>{return await getvalue("get_vender",obj)}
const get_venders     =async(obj)     =>{return await getvalues("get_venders",obj)}
const del_vender     =async(obj)     =>{return await setvalue("del_vender",obj)}
const get_venderzw     =async(obj)     =>{return await getvalues("get_venderzw",obj)}

const add_style  =async(obj,share)     =>{return await setvalue("add_style",obj,share)}
const upd_style    =async(obj,share)     =>{return await setvalue("upd_style",obj,share)}
const get_style     =async(obj)     =>{return await getvalue("get_style",obj)}
const get_styles     =async(obj)     =>{return await getvalues("get_styles",obj)}
const del_style     =async(obj)     =>{return await setvalue("del_style",obj)}
const get_styles_n     =async(obj)     =>{return await getvalues("get_styles_n",obj)}
const get_styles_s     =async(obj)     =>{return await getvalues("get_styles_s",obj)}
const get_styles_ss     =async(obj)     =>{return await getvalues("get_styles_ss",obj)}
const get_styles_v     =async(obj)     =>{return await getvalues("get_styles_v",obj)}
const get_vpages     =async(obj)     =>{return await getvalues("get_vpages",obj)}
const get_vplaces     =async(obj)     =>{return await getvalues("get_vplaces",obj)}


const add_ds  =async(obj,share)     =>{return await setvalue("add_ds",obj,share)}
const upd_ds    =async(obj,share)     =>{return await setvalue("upd_ds",obj,share)}
const get_ds     =async(obj)     =>{return await getvalue("get_ds",obj)}
const get_allds     =async(obj)     =>{return await getvalues("get_allds",obj)}
const del_ds     =async(obj)     =>{return await setvalue("del_ds",obj)}

const add_device  =async(obj,share)     =>{return await setvalue("add_device",obj,share)}
const upd_device    =async(obj,share)     =>{return await setvalue("upd_device",obj,share)}
const get_device     =async(obj)     =>{return await getvalue("get_device",obj)}
const get_devices     =async(obj)     =>{return await getvalues("get_devices",obj)}
const del_device     =async(obj)     =>{return await setvalue("del_device",obj)}

const add_eda  =async(obj,share)     =>{return await setvalue("add_eda",obj,share)}
const upd_eda    =async(obj,share)     =>{return await setvalue("upd_eda",obj,share)}
const get_eda     =async(obj)     =>{return await getvalue("get_eda",obj)}
const get_edas     =async(obj)     =>{return await getvalues("get_edas",obj)}
const del_eda     =async(obj)     =>{return await setvalue("del_eda",obj)}
const get_edatypes     =async(obj)     =>{return await getvalues("get_edatypes",obj)}

const add_clist  =async(obj,share)     =>{return await setvalue("add_clist",obj,share)}
const upd_clist    =async(obj,share)     =>{return await setvalue("upd_clist",obj,share)}
const get_clist     =async(obj)     =>{return await getvalue("get_clist",obj)}
const get_clists     =async(obj)     =>{return await getvalues("get_clists",obj)}
const del_clist     =async(obj)     =>{return await setvalue("del_clist",obj)}

export default {
    setvalue,getvalue,getvalues,
    update_info,get_info,get_logininfo,
    create_group,join_group,get_mygroup,get_group,get_joinmems,delete_memgroup,delete_group,agree_memgroup,
    get_joindmems,get_joindgroup,
    add_project,upd_project,get_project,get_projects,del_project,get_cspros,get_csstates,get_cslines,get_csstyles,
    add_plan,upd_plan,del_plan,get_plans,get_plan,get_oneday,finish_plan,recheck_plan,
    add_today,upd_today,get_today,del_today,get_gplans,
    add_blog,upd_blog,get_blog,get_blogs,get_blogid,del_blog,get_diarys,get_conts,get_bsconts,get_names,
    add_file,upd_file,get_file,get_files,del_file,get_pjfiles,
    add_vender,upd_vender,get_vender,get_venders,del_vender,get_venderzw,
    add_style,upd_style,get_style,get_styles,del_style,get_styles_n,get_styles_s,get_styles_ss,get_styles_v,get_vpages,get_vplaces,
    add_ds,upd_ds,get_ds,get_allds,del_ds,getcs_projects,
    add_eda,upd_eda,get_eda,get_edas,del_eda,get_edatypes,
    add_device,upd_device,get_device,get_devices,del_device,
    add_clist,upd_clist,get_clist,get_clists,del_clist,
    get_nblogs,bind_nblog
}
