<template>
  <Panel header="个人信息">
    <a><b style="color:#2c3e50 ;font-weight: bold;">{{ info.uAccount }}</b> ，欢迎登录硬知识分享平台 </a>

    <div>

      <div class="div_33_100 margin_top">
        <div class="p-inputgroup flex-1 ">
          <span class="p-float-label">
            <InputText id="username" v-model="info.uName" :disabled="isEdit" />
            <label for="username">姓名</label>
          </span>
        </div>
      </div>

      <div class="div_33_100 margin_top">
        <div class="p-inputgroup flex-1 ">
          <span class="p-float-label">
            <InputText id="uPhone" :disabled="isEdit" v-model="info.uPhone" />
            <label for="uPhone">电话</label>
          </span>
        </div>
      </div>


      <div class="div_33_100 margin_top">
        <div class="p-inputgroup flex-1 ">
          <span class="p-float-label">
            <InputText id="uDegree" :disabled="isEdit" v-model="info.uDegree" />
            <label for="uDegree">学历</label>
          </span>
        </div>
      </div>



      <div class="div_33_100 margin_top">
        <div class="p-inputgroup flex-1 ">
          <span class="p-float-label">
            <InputText id="uSchool" :disabled="isEdit" v-model="info.uSchool" />
            <label for="uSchool">学校</label>
          </span>
        </div>
      </div>


      <div class="div_33_100 margin_top">
        <div class="p-inputgroup flex-1 ">
          <span class="p-float-label">
            <InputText id="uExpert" :disabled="isEdit" v-model="info.uExpert" />
            <label for="uExpert">专业</label>
          </span>
        </div>
      </div>


      <div class="div_33_100 margin_top">
        <div class="p-inputgroup flex-1 ">
          <span class="p-float-label">
            <InputText id="uTonwn" :disabled="isEdit" v-model="info.uPlace" />
            <label for="uTonwn">居住地</label>
          </span>
        </div>
      </div>

      <div class="div_33_100 margin_top">
        <div class="p-inputgroup flex-1 ">
          <span class="p-float-label">
            <InputText id="uCompany" :disabled="isEdit" v-model="info.uCompany" />
            <label for="uCompany">目前所在公司</label>
          </span>
        </div>
      </div>

      <div class="div_33_100 margin_top">
        <div class="p-inputgroup flex-1 ">
          <span class="p-float-label">
            <InputText id="uZhiwei" :disabled="isEdit" v-model="info.uZhiwei" />
            <label for="uZhiwei">目前职位</label>
          </span>
        </div>
      </div>

      <div class="div_33_100 margin_top">
        <div class="p-inputgroup flex-1 ">
          <span class="p-float-label">
            <InputText id="uGangwei" :disabled="isEdit" v-model="info.uGangwei" />
            <label for="uGangwei">目前岗位</label>
          </span>
        </div>
      </div>

      <div class="div_33_100 margin_top">
        <div class="p-inputgroup flex-1 ">
          <span class="p-float-label">
            <Calendar id="uBirthday" v-model="info.uBirthDay" :disabled="isEdit" />
            <label for="uBirthday">生日</label>
          </span>
        </div>
      </div>


      <div class="div_33_100 margin_top">
        <div class="p-inputgroup flex-1 ">
          <span class="p-float-label">
            <Calendar id="uGdday" v-model="info.uGDay" :disabled="isEdit" />
            <label for="uGdday">毕业时间</label>
          </span>
        </div>
      </div>

      <div class="div_33_100 margin_top">
        <div class="p-inputgroup flex-1 ">
          <span class="p-float-label">
            <Calendar id="uRZSJ" v-model="info.uWorkDay" :disabled="isEdit" />
            <label for="uRZSJ">入职时间</label>
          </span>
        </div>
      </div>

      <div class="div_33_100 margin_top">
        <div class="p-inputgroup flex-1 ">
          <span class="p-float-label">
            <InputText id="uDLIP" disabled v-model="logininfo.uIP" />
            <label for="uDLIP">登录IP</label>
          </span>
        </div>
      </div>

      <div class="div_33_100 margin_top">
        <div class="p-inputgroup flex-1 ">
          <span class="p-float-label">
            <InputText id="uDLMAC" disabled v-model="logininfo.uMAC" />
            <label for="uDLMAC">登录MAC</label>
          </span>
        </div>
      </div>
      <div style="clear:both;"></div>
      <div class="place1em"></div>
    </div>
    <div>

      <ToggleButton style="margin: 0.1em;" v-model="isEdit" onLabel="修改" offLabel="取消" onIcon="pi pi-user-edit"
        offIcon="pi pi-angle-left" class="w-9rem" />
      <Button style="margin: 0.1em;" label="更新" @click="update_Info(info); isEdit = true;" v-if="!isEdit"
        icon="pi pi-check" />
      <Button style="margin: 0.1em;" label="注销" @click="useApi.jumpto('/logout')" icon="pi pi-android" />
    </div>

  </Panel>
  <div class="place1em"></div>
  <div class="card" v-if="info.uCompany != null && info.uCompany.length >= 5 && isEdit">
    <TabView>
      <TabPanel header="组织创建">
        <div class="margin_top div_34_100">
          <div class="p-inputgroup flex-1 ">
            <span class="p-float-label">
              <InputText id="ruCompanyName" maxlength="30" v-model="groupinfo.uCompanyName" />
              <label for="ruCompanyName">您所在的公司名称</label>
            </span>
          </div>
        </div>
        <div class="margin_top div_66_100">
          <div class="div_60_100">
            <div class="p-inputgroup flex-1 ">
              <span class="p-float-label">
                <InputText id="ruGroupName" maxlength="30" v-model="groupinfo.uGroupName" />
                <label for="ruGroupName">小组名称[30字]</label>
              </span>
            </div>
          </div>
          <div class="div_40_100">
            <div class="place1em lshow"></div>
            <Button label="创建" icon="pi pi-users" @click="create_group(groupinfo); get_myinfo()"
              title="个人信息填写正确的公司名称后，才可以创建小组" :disabled="info.uCompany == null || info.uCompany.length < 5" />
            <Button label="加入" icon="pi pi-plus" @click="join_group(groupinfo); get_myinfo()"
              title="个人信息填写正确的公司名称后，才可以加入小组" :disabled="info.uCompany == null || info.uCompany.length < 5" />

          </div>
          <div class="clear"></div>

        </div>

        <div class="clear"></div>
        <div class="margin_top">
          <Chip v-bind:header="uGroupName" style="margin:0.5em;cursor:pointer"
            v-for="{ Id, uGroupName, uManager, uGroupId, uCompanyName } in myGroups" :key="Id" :label="uGroupName"
            image="/img/User04.png" @click="select_group(uGroupName, Id, uManager, uCompanyName, uGroupId)" />
          <Chip v-bind:header="uGroupName" style="margin:0.5em;cursor:pointer"
            v-for="{ Id, uGroupName, uManager, uGroupId, uCompanyName } in myMemGroups" :key="Id" :label="uGroupName"
            image="/img/User04.png" @click="select_group(uGroupName, Id, uManager, uCompanyName, uGroupId)" />

        </div>
        <div class="place1em"></div>
        <div v-if="isShowMng">

          <div class="place1em"></div>
          <div class="header_line">管理您创建的小组</div>
          <div class="place1em"></div>
          <GroupCreate :grpid="select_gpid" :grpname="select_gpname" @re_get_members="get_myinfo(); isShowMng = false;"
            @re_get_mygroup="get_myinfo(); isShowMng = false;" :uAccount="info.uId"></GroupCreate>

          <div class="place1em"></div>
          <div class="header_line">为小组制定目标,一般设定一层就足够</div>
          <div class="place1em"></div>
          <CommonPlan :isparent="-1" :userid="info.uId" ref="Finished" pstyle="GroupGoal" pheader="制定小组目标"
            :setroledata="setroledata"></CommonPlan>

          <div class="place1em"></div>
          <div class="header_line">为小组设定能力地图,让小组朝着您期望的方向发展,数据一般不超过两层</div>
          <div class="place1em"></div>
          <CommonPlan :isparent="-1" :userid="info.uId" ref="Finished" pstyle="GroupMap" pheader="制定考核地图"
            :setroledata="setroledata"></CommonPlan>


        </div>


      </TabPanel>

      <TabPanel header="组织架构">
        <div style="pad_1_01">
          你可以将您加入的小组与您创建的小组关联起来，这样您的成员将和您加入的组织，共享一些元素内容。
        </div>
      </TabPanel>


    </TabView>
  </div>
  <div class="card" v-else>
    <div style="padding: 1em;">
      简单填写个人信息后，就可以创建或者加入小组了,加入小组后,您将可以与小组成员共享您的资源和经验。
    </div>

  </div>
  <div class="place1em"></div>

  <Panel header="我的私密笔记">
    <FireBlogs type="diary"></FireBlogs>
  </Panel>
  <div class="place1em"></div>
</template>

<script setup>
import Calendar from 'primevue/calendar';
import InputText from 'primevue/inputtext';
import Button from 'primevue/button';
import Panel from 'primevue/panel';
import ToggleButton from 'primevue/togglebutton';
import TabView from 'primevue/tabview';
import TabPanel from 'primevue/tabpanel';
import GroupCreate from '@/components/GroupCreate.vue';
import CommonPlan from '@/components/CommonPlan.vue';
import Chip from 'primevue/chip';
import useSlade from '@/services/useSlade'
import useApi from '@/services/useApi'
import { ref, onMounted } from "vue";
import FireBlogs from './FireBlogs.vue';
import common from '@/services/useCommon'
const get_sdate = common.get_sdate;


let myinfo = ref({});
let logininfo = ref({});
const update_Info = useSlade.update_info;

let groupinfo = ref({})
groupinfo.value.uCompanyName = '每日硬知识'
groupinfo.value.uGroupName = '硬件知识积累'
const create_group = useSlade.create_group;
const join_group = useSlade.join_group;

let isEdit = ref(true);

let select_gpname = ref('');
let select_gpid = ref(-1);
let select_creator = ref('');
let myGroups = ref({});
let myMemGroups = ref({});


const get_myinfo = async () => {
  myinfo.value = await useSlade.get_info();
  if (myinfo.value.uBirthDay)
    myinfo.value.uBirthDay = get_sdate(myinfo.value.uBirthDay);
  if (myinfo.value.uGDay)
    myinfo.value.uGDay = get_sdate(myinfo.value.uGDay);
  if (myinfo.value.uWorkDay)
    myinfo.value.uWorkDay = get_sdate(myinfo.value.uWorkDay);
  logininfo.value = await useSlade.get_logininfo();
  myGroups.value = await useSlade.get_mygroup();
}



onMounted(() => {
  get_myinfo();
  //alert(loginuser);
})
const info = myinfo;
let setroledata = ref({})
let isShowMng = ref(false);
const select_group = (gpname, gpid, cuser, company, ugpid) => {
  select_gpname.value = gpname;
  select_gpid.value = ugpid != 0 ? ugpid : gpid;
  select_creator.value = cuser;
  isShowMng.value = true;
  groupinfo.value.uGroupName = gpname;
  groupinfo.value.uCompanyName = company
  setroledata.value = { rdRange: { rdRange: '组内成员可见' }, wrRange: { wrRange: '仅自己编辑' }, sShareGroup: "{\"Id\":" + select_gpid.value + ",\"uGroupName\":\"" + gpname + "\",\"uCompanyName\":\"" + company + "\"}" }

}

document.title = '[个人中心]-[碳硅锗兮]';
document.querySelector('meta[name="keywords"]').setAttribute('content', '碳硅锗兮登录和注册,分享软硬件设计笔记和资源经验')
document.querySelector('meta[name="description"]').setAttribute('content', '碳硅锗兮登录和注册,分享软硬件设计笔记和资源经验')



</script>
<style>
.header_line {
  border-top: 1px solid #c4c4c4;
  padding: 1em;
}</style>

