import { ref, onMounted, watchEffect } from 'vue';
import mapcell from '@/services/useMap'
import useSlade from '@/services/useSlade';
import useCommon from '@/services/useCommon';
import useAlert from '@/services/useAlert';


export default function useCommonMap(props) {

    const getrolevalue = async (index, obj) => {
        if (obj) {
            //根据底层选择的Group信息获取项目信息
            let group = { pGroupInfo: obj.sShareGroup }
            mydata.value[index].pProjectInfos = await useSlade.get_cspros(group)
            //将底层传递出来的share信息赋值给数组元素
            mydata.value[index].share = obj
        }
    }
    const checkmydata = (obj) => {
        if (obj.children) {
            obj.children = useCommon.jsons_o(obj.children)
            if (obj.children.length > 0) {
                obj.children.forEach(item => {
                    checkmydata(item)
                })
            }
        }
        if (obj.pBelongInfo)
            obj.pBelongInfo = useCommon.jsons_o(obj.sShareGroup)
        if (obj.pProjectInfo)
            obj.pProjectInfo = useCommon.jsons_o(obj.pProjectInfo)
    }
    const add_plan = async (obj) => {
        if ((obj.pPlan.length > 0 && obj.pProjectInfo && obj.pProjectInfo.pName) || (props.pstyle == 'GroupGoal' || props.pstyle == 'GroupMap' || props.pstyle == 'Msg'|| props.pstyle == 'Blogs')) {
            let sendobj = before_post(obj);
            await useSlade.add_plan(sendobj, sendobj.share)
            obj.isEdit = false
            getaffairs();
        }
        else {
            useAlert.salert("必须选择正确的项目信息")
        }
    }
    const upd_plan = async (obj) => {
        if ((obj.pPlan.length > 0 && obj.pProjectInfo && obj.pProjectInfo.pName) || (props.pstyle == 'GroupGoal' || props.pstyle == 'GroupMap'|| props.pstyle == 'Msg'|| props.pstyle == 'Blogs')) {
            let sendobj = before_post(obj);
            await useSlade.upd_plan(sendobj, sendobj.share)
            obj.isChange = false

        }
        else {
            useAlert.salert("必须选择正确的项目信息")
        }
    }
    async function del_plan(index, planmodel, pid) {
        planmodel.splice(index, 1)
        let xx = 0;
        planmodel.forEach(plan => {
            plan.index = xx;
            xx++;
        })
        if (pid > 0) {
            let sendobj = { pId: pid, pStyle: props.pstyle };
            await useSlade.del_plan(sendobj)
        }
        return planmodel
    }
    async function del_childplan(index, planmodel) {
        planmodel.splice(index, 1)
        let xx = 0;
        planmodel.forEach(plan => {
            plan.index = xx;
            xx++;
        })
        return planmodel
    }
    const fdate = useCommon.get_sdate;
    const cal_start = mapcell.cal_start;
    const cal_end = mapcell.cal_end;


    async function finish_plan(index, planmodel, pid) {

        await useSlade.finish_plan({ pId: pid })
        planmodel[index].pStatus = '已完成'
    }

    async function recheck_plan(index, planmodel, pid) {
        await useSlade.recheck_plan({ pId: pid })
        planmodel[index].pStatus = '进行中'
    }



    const before_post = (obj) => {
        let sendobj = useCommon.o_o(obj)
        obj.pBelongInfo = useCommon.jsons_o(sendobj.share.sShareGroup)
        sendobj.pBelongInfo = sendobj.share.sShareGroup;
        sendobj.pProjectInfo = useCommon.jsono_s(sendobj.pProjectInfo)
        if (props.pstyle == 'ProjectQuestion' || props.pstyle == 'ProjectPlan')
            sendobj.pBelongInfo = useCommon.jsono_s(obj.pProjectInfo)
        return sendobj;
    }
    const getaffairs = async () => {
        let plan = {}
        plan.pStyle = props.pstyle

        if (props.setroledata && props.setroledata.sShareGroup)
            plan.pBelongInfo = useCommon.jsono_s(props.setroledata.sShareGroup)
        if (props.isparent < 0) {
            if (props.pstyle == "ProjectQuestion" || props.pstyle == "ProjectPlan" || props.pstyle == "Blogs") {
                plan.pBelongInfo = useCommon.jsono_s(props.belonginfo)
            }
            let getdatas = await useSlade.get_plans(plan);
            getdatas.forEach((getdata) => {
                checkmydata(getdata);
            })
            mydata.value = getdatas;
        }
        else
            mydata.value = props.datas;
    }

    const getnblogs = async () => {
        let plan = {}
        plan.pStyle = props.pstyle
        plan.pId = props.pid

        if (props.setroledata && props.setroledata.sShareGroup)
            plan.pBelongInfo = useCommon.jsono_s(props.setroledata.sShareGroup)
        if (props.isparent < 0) {
            if (props.pstyle == "ProjectQuestion" || props.pstyle == "ProjectPlan" || props.pstyle == "Blogs") {
                plan.pBelongInfo = useCommon.jsono_s(props.belonginfo)
            }
            let getdatas = await useSlade.get_nblogs(plan);
            getdatas.forEach((getdata) => {
                checkmydata(getdata);
            })
            mydata.value = getdatas;
        }
        else
            mydata.value = props.datas;
    }




    function add_input(baseobjects, style) {
        let plandata = {};
        if (!baseobjects)
            baseobjects = []
        plandata.index = baseobjects.length;
        plandata.pId = -1;
        plandata.pPlan = "";
        plandata.pCost = 0.1;
        plandata.pStart = new Date();
        plandata.pEnd = new Date();
        plandata.pParentId = 0;
        plandata.pStyle = style;
        plandata.isEdit = true;
        plandata.isChange = false;
        plandata.children = []
        if (props.pstyle == "ProjectQuestion" || props.pstyle == "ProjectPlan") {
            plandata.pProjectInfo = props.belonginfo
        }
        baseobjects.push(plandata);

    }

    function add_child(thisplan, style) {
        let plandata = useCommon.o_o(thisplan);
        if (!thisplan.children)
            thisplan.children = []
        plandata.index = thisplan.children.length;
        plandata.pPlan = " ";
        plandata.pId = -1;
        plandata.pCost = 0.1;
        plandata.pStart = new Date();
        plandata.pEnd = new Date();
        plandata.pParentId = thisplan.pId;
        plandata.pStyle = style;
        plandata.children = [];
        plandata.isEdit = true;
        plandata.isChange = false;
        plandata.isChildShow = true;
        plandata.is_ededit = false;
        plandata.roledisable = true;
        thisplan.children.push(plandata);

        return thisplan;
    }

    const get_gplans = async (roleinfo) => {
        let plan = {}
        plan.pStyle = props.pstyle
        plan.pBelongInfo = useCommon.jsono_s(roleinfo.sShareGroup)
        if (props.isparent < 0) {
            mydata.value = await useSlade.get_gplans(plan)
        }
        else
            mydata.value = props.datas;
    }

    /*在加载完成后，开始获取显示数据 */
    onMounted(() => {
        //
        if (props.pstyle == "MyFinished") {
            sel_day.pStart = new Date()
            getoneday(sel_day);
        }        
        else
        {
            if(props.pstyle=="Blogs" && props.pid>0)
                getnblogs();
            else
                getaffairs();
        }
    })

    /*在顶层引用该控件时，设定isparent为真,控件根据该值获得首次数据，子控件为假，则获取传递的children值 */
    /*这部分需要引用 */
    let mydata = ref([]);
    /**获取输入框中的项目信息，成员信息，以及小组信息 */
    async function operate_myafairs(affairs) {
        let sendobj = useCommon.o_o(affairs)
        sendobj.pLastTime = useCommon.format_date(sendobj.pLastTime, "yyyy-MM-dd")

        if (affairs.pId <= 0)
            await useSlade.add_today(sendobj)
        else
            await useSlade.upd_today(sendobj)
        getaffairs()
    }
    async function del_today(affairs) {
        await useSlade.del_today(affairs)
        getaffairs()
    }
    let td_data = ref({});
    td_data.value.pLastTime = (new Date)
    td_data.value.pId = -1
    td_data.value.pPlanID = 0
    td_data.value.pCost = 0.1
    td_data.value.pProgress = ''


    const opentoday = (obj) => {
        obj.is_ededit = true;
        td_data.value.pPlanID = obj.pId;
        td_data.value.pBelongInfo = useCommon.jsono_s(obj.sShareGroup);
        td_data.value.pProjectInfo = useCommon.jsono_s(obj.pProjectInfo);
        findaffair(obj.pId, td_data.value)
    }

    async function findaffair(planid, today) {
        let todaypost = { pId: -1, pPlanID: planid, pLastTime: useCommon.format_date(today.pLastTime, "yyyy-MM-dd") }

        let todayresult = await useSlade.get_today(todaypost);

        if (todayresult && todayresult.pId && todayresult.pId > 0) {

            td_data.value = todayresult
            td_data.value.pLastTime = useCommon.format_date(td_data.value.pLastTime, "yyyy-MM-dd")

        }
        else {
            td_data.value.pId = -1
            td_data.value.pCost = 0.1
            td_data.value.pProgress = ''
        }

    }

    let sel_day = {}

    async function getoneday(today) {
        let todaypost = { pStart: useCommon.format_date(today.pStart, "yyyy-MM-dd") }
        if (props.isparent < 0) {
            let getdatas = await useSlade.get_oneday(todaypost);
            getdatas.forEach((getdata) => {
                checkmydata(getdata);
            })
            mydata.value = getdatas;

        }
        else
            mydata.value = props.datas;
    }

    watchEffect(() => {
        props.userid;
        props.setroledata
        //getaffairs()

    })



    return {
        mydata,
        td_data,
        props,
        sel_day,
        getrolevalue,
        checkmydata,
        before_post,
        add_plan,
        upd_plan,
        del_plan,
        del_childplan,
        fdate,
        add_child,
        add_input,
        cal_start,
        cal_end,
        finish_plan,
        getaffairs,
        operate_myafairs,
        del_today,
        opentoday,
        findaffair,
        get_gplans,
        getoneday,
        recheck_plan,
        getnblogs
    }

}


