<template>
    <Button icon="pi pi-file-edit" @click="switch_edit" v-if="authed"
        style="margin: 0.1em;position: fixed;right: 1em;bottom: 6em;z-index: 99;" />
    <Button icon="pi pi-book" @click="useApi.jumpto('/datasheet/show')" title="查看电子元器件"
            style="margin: 0.1em;position: fixed;right: 1em;bottom: 2em;z-index: 99;" />
    <div v-if="!isEdit">
        <div style="height: 1.5em;"></div>
        <div>
            <SearchBlock :sname="'器件'" @onsearch="filters" :flabel="filter_arr"></SearchBlock>
        </div>        
        <div class="padding" style="min-height: 1500px;">
            <div v-for="{ Description, Name, Style1, Style2, Manufacture, Price, Id,Code } in blogs" :key="Id"
                 class="div_100">
                <div style="padding: 0.5em;border-top: 1px solid rgb(221, 218, 218);">
                    <div class="div_20_100 align_lleft_scenter"><i class="pi pi-globe"></i> {{
                        useCommon.jsons_o(Manufacture).vName }}</div>
                    <div class="div_20_100 align_lleft_scenter"><i class="pi pi-bookmark"></i> {{
                        useCommon.jsons_o(Style1).vStyle1 }}</div>
                    <div class="div_20_100 align_lleft_scenter"><i class="pi pi-bookmark"></i> {{
                        useCommon.jsons_o(Style2).vStyle2 }}</div>
                    <div class="div_20_100 align_lleft_scenter"><i class="pi pi-tag"></i> {{ Name }}</div>
                    <div class="div_20_100 align_lleft_scenter"><i class="pi pi-money-bill"></i> {{ Price }}</div>
                    <div class="div_100 align_lleft_scenter" style="height: 1.5em; overflow: hidden;">
                        <i class="pi pi-home"></i> {{ Description }}
                    </div>
                    <div class="div_20_100 align_lleft_scenter">
                        <i class="pi pi-globe"></i> {{Code }}
                    </div>
                    <div class="clear"></div>
                </div>
            </div>
            <div class="clear"></div>

        </div>
    </div>
    <div v-else>
        <DeviceEdit @ondatachange="listshow" :blog_id="projectname"></DeviceEdit>
        <EDAEdit @ondatachange="listshow" :blog_id="projectname"></EDAEdit>
    </div>
</template>
    
<script setup>
import DeviceEdit from '@/components/DeviceEdit.vue';
import EDAEdit from '@/components/EDAEdit.vue';
import useBlog from '@/services/useBlog';
import useApi from "@/services/useApi";
import { ref, onMounted, defineProps } from "vue";
import Button from 'primevue/button';
import { useStore } from "vuex";
import SearchBlock from "@/components/SearchBlock.vue";
import useSlade from '@/services/useSlade';
import useCommon from '@/services/useCommon'

const props = defineProps({
    filter: String, 
    project_id: String
})
let isEdit = ref(false)
let projectname = ref('')
const store = useStore();
let authed = ref(store.state.authed);
let isadmin = ref(false);
let blogs = ref([])
let local_blogs = ref([])

const filters = (name, type) => {
    blogs.value = useApi.search_item(name, type, local_blogs.value)
}
const filter_arr = [{ name: '名称', type: 'fName' }, { name: '标签', type: 'fLabel' }]
const listshow = async () => {
    blogs.value = await useSlade.get_devices()
    console.log(blogs.value)
    local_blogs.value = blogs.value

}

const get_role = async () => {
    let role_data = await useBlog.get_role();
    isadmin.value = (role_data == 'SysAdmin')
}

const switch_edit = () => {
    if (isEdit.value) {
        isEdit.value = false;
        projectname = '';
        window.location.href = '/device/show'
    }
    else {
        isEdit.value = true;
    }
}


onMounted(() => {
    listshow();
    if (authed.value)
        get_role()
    if (props.filter && props.filter.length > 0) {
        filters()
    }
    if (props.project_id) {
        projectname.value = props.project_id
        isEdit.value = true;
    }
    document.title = '[器件选型]-[碳硅锗兮]';
document.querySelector('meta[name="keywords"]').setAttribute('content', '碳硅锗兮登录和注册,分享软硬件设计笔记和资源经验')
document.querySelector('meta[name="description"]').setAttribute('content', '碳硅锗兮登录和注册,分享软硬件设计笔记和资源经验')
})
</script>
    