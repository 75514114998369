
<template>
  <div>
    <FileUpload mode="basic" name="demo[]" accept="image/*" auto :disabled="group.canEdit == 0"
      :maxFileSize="2000000"
      :style="{ 'width': '100%', 'height': '15em', 'background-size': 'cover', 'background-image': group.pImg }"
      customUpload @uploader="customBase64Uploader" />
    <div id="scrollspy-collapsible">
      <div class="card">
        <div style="position: relative;">

        </div>
        <div>
          <div class="card-body">
            <div>
              <div class="p-inputgroup flex-1 ">
                <InputText placeholder="项目名称" v-model="group.pName" @change="selectid = bid"  :disabled="group.canEdit == 0"/><Button icon="pi pi-search" 
                  title="点击生成笔记ID" @click="search_blog()" />
              </div>

              <div style="height: 0.5em;"></div>
              <div class="p-inputgroup flex-1 ">
                <InputText placeholder="项目进度" v-model="group.pProgress" type="number" :disabled="group.canEdit == 0" /> 
              </div>
              <div style="height: 0.5em;"></div>
              <div class="div_33_100">
                <div class="p-inputgroup flex-1 ">
                  <Dropdown v-model="group.pLine" :options="mylines" option-label="pLine" editable placeholder="所属产品线" :disabled="group.canEdit == 0"
                    class="w-full md:w-14rem" :loading="false" />
                </div>
              </div>

              <div class="div_33_100">
                <div class="p-inputgroup flex-1 ">
                  <Dropdown v-model="group.pStyle" editable :options="mystyles" optionLabel="pStyle" placeholder="项目类型" :disabled="group.canEdit == 0"
                    class="w-full md:w-14rem" :loading="false" />

                </div>
              </div>

              <div class="div_33_100">
                <div class="p-inputgroup flex-1 ">
                  <Dropdown v-model="group.pState" editable :options="mystatus" optionLabel="pState" placeholder="项目阶段" :disabled="group.canEdit == 0"
                    class="w-full md:w-14rem" :loading="false" />

                </div>
              </div>
              <div style="height: 0.5em;"></div>
              <ObjectShare widthclass="div_33_100" @ondatachange="getrolevalue" :sharedata="getshare" :disabled="group.canEdit == 0"></ObjectShare>
            </div>
          </div>
          <div>
            <Button icon="pi pi-plus" @click="create_blog" v-if="group.pId <= 0"
              style="margin: 0.1em;position: fixed;right: 1em;bottom: 6em;z-index: 99;" />
            <Button icon="pi pi-refresh" @click="update_blog(group.pId)" :title="group.pId"
              v-if="group.pId > 0 && group.canEdit == 1"
              style="margin: 0.1em;position: fixed;right: 1em;bottom: 6em;z-index: 99;" />
            <Button icon="pi pi-minus" @click="delete_blog(group.pId)" :title="group.pId"
              v-if="group.pId > 0 && group.canEdit == 1" style="margin: 0.1em;" />
            <Button icon="pi pi-video" style="margin: 0.1em;position: fixed;right: 1em;bottom: 10em;z-index: 99;"
                @click="useApi.jumpto('/project/' + props.blog_id)" />
          </div>
          <div style="text-align: left;padding: 1em;">

          </div>
        </div>

      </div>

      <div style="height: 1em;"></div>
      <div data-mdb-spy="scroll" data-mdb-target="#scrollspy-collapsible" data-mdb-offset="0" class="scrollspy-example">
        编辑项目规格以及需求
        <editor v-model="group.pDescription" id="EditPanel2" :init="useEditor.init_old" />

      </div>
      <div style="height: 1em;"></div>
    </div>

  </div>
</template>
  
<script setup>
import Editor from '@tinymce/tinymce-vue'
import { ref, defineProps, onMounted, defineEmits } from "vue";
import InputText from 'primevue/inputtext';
import FileUpload from 'primevue/fileupload';
import Button from 'primevue/button';
import Dropdown from 'primevue/dropdown';
import useCommon from '@/services/useCommon';
import useApi from '@/services/useApi';
import useSlade from '@/services/useSlade';
import useEditor from '@/services/useEditor';
//let message=ref('')

import ObjectShare from './ObjectShare.vue';
import useAlert from '@/services/useAlert';
let sendshare = ref({});
let getshare = ref({});
sendshare.value.Id = -1
const getrolevalue = (obj) => {
  sendshare.value = obj
}




let oclass = ref('height_35')
let bid = ref('')
let select_name = ref('')
let selectid = ref('')

const props = defineProps({
  blog_id: String
})

let group = ref({});
group.value.pImg = ref('url(images/f2.jpg)')
group.value.pId = -1;
const mygroups = ref([]);
const mylines = ref([]);
const mystyles = ref([]);
const mystatus = ref([]);

const get_binfo = async () => {
  mygroups.value = await useSlade.get_joindgroup();
  mylines.value = await useSlade.get_cslines(); 
  mystyles.value=await useSlade.get_csstyles();
  mystatus.value=await useSlade.get_csstates();
}

// const get_groupinfo = async (gpid) => {
//   mylines.value = await useProject.get_lines(gpid)
//   mystyles.value = await useProject.get_styles(gpid)
//   mystatus.value = await useProject.get_status(gpid)

// }



onMounted(() => {
  get_binfo();
  if (props.blog_id && props.blog_id.length > 0) {
    oclass.value = 'height_35'
    select_name.value = props.blog_id;
    search_blog(props.blog_id);
  }
  else {
    oclass.value = 'height_100'
  }

})


const search_blog = async (projectid) => {
  let postgroup = useCommon.o_o(group.value)
  beforepost(postgroup);
  if (projectid)
    postgroup.pId = projectid
  let getdata = await useSlade.get_project(postgroup);
  if (getdata && getdata.pName) {
    group.value = getdata
    getshare.value = getdata
    console.log(getshare.value)
  }
  else {
    group.value.pId = -1
  }
}

const beforepost = (obj) => {
  if (obj.pStyle)
    obj.pStyle = useCommon.o_s(obj.pStyle, "pStyle")
  if (obj.pState)
    obj.pState = useCommon.o_s(obj.pState, "pState")
  if (obj.pLine)
    obj.pLine = useCommon.o_s(obj.pLine, "pLine")
  if (sendshare.value.sShareGroup)
    obj.pGroupInfo = (sendshare.value.sShareGroup)
}

const create_blog = async () => {

  let postgroup = useCommon.o_o(group.value)
  beforepost(postgroup);
  console.log(sendshare.value)
  if (sendshare.value.rdRange.length > 3 &&sendshare.value.wrRange.length > 3 &&sendshare.value.sShareGroup.length > 3&&postgroup.pName&&postgroup.pName.length>3) {
    await useSlade.add_project(postgroup, sendshare.value)
    search_blog()
    ondatachange()
  }
  else
    useAlert.salert("项目信息太少了");
}

const update_blog = async () => {
  let postgroup = useCommon.o_o(group.value)
  beforepost(postgroup);
  console.log(sendshare.value)
  if (postgroup.pName&&postgroup.pName.length>3) {
    await useSlade.upd_project(postgroup, sendshare.value)
    search_blog()
    ondatachange()
  }else
    useAlert.salert("项目信息太少了");
}


const delete_blog = async () => {
  let postgroup = useCommon.o_o(group.value)
  beforepost(postgroup);
  await useSlade.del_project(postgroup, sendshare.value)
  search_blog()
  ondatachange()
}

const customBase64Uploader = async (event) => {

  const file = event.files[0];
  let formData = new FormData()
  formData.append('files', file)
  useApi.uploadfile('TinyMCE/Upload', formData).then(res => {
    group.value.pImg = 'url(' + useApi.myapiurl + res.data.location + ')';

  })
}

const emits = defineEmits(['ondatachange']);
function ondatachange() {
  emits("ondatachange")
}










</script>

  